<template>
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header bg-gray">
                    <h3 class="card-title"><i class="fab fa-telegram-plane mr-2"></i>{{ $t('general.enviodemensajes') }}</h3>            
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                               <!-- <label for="exampleInputEmail1">Info</label>
                                <textarea v-model="cuerpo_mensaje" class="form-control" rows="8"/>-->
                                <Editor v-model="cuerpo_mensaje" editorStyle="height: 320px" />
                            </div>
                        </div>
                        <div class="col-md-5"></div>
                        <div class="col-md-3">
                            <button class="btn btn-success btn-block" @click="enviarMensaje()" type="button">{{ $t('general.enviar') }}</button>
                        </div>
                    </div>
                </div>   
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header bg-gray">
                    <h3 class="card-title"><i class="fas fa-list mr-2"></i>{{ $t('general.historialdemensajes') }}</h3>
                </div>
                <div class="card-body">
                    <div class="direct-chat-messages" style="height: 550px;">
                        <div class="direct-chat-msg" v-for="mensaje in mensajes" :key="mensaje.id_mensaje">
                            <div class="direct-chat-infos clearfix">
                                <span class="direct-chat-name float-left">{{mensaje.nombre_usuario }}</span>
                                <span class="direct-chat-timestamp float-right">{{new Date(mensaje.fecha*1000).toLocaleString("es-es", { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) }}</span>
                            </div>
                            <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg" alt="message user image">
                            <div class="direct-chat-text">
                                <p v-html="mensaje.texto"></p>
                            </div><div class="row">
                            <div class="col-md-11"></div>
                            <i v-if="mensaje.visto == 1" class="pi pi-check visto" stlye="font-size: small">Visto</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
    import Editor from 'primevue/editor';

    export default ({
        props: ['id','idvisita'],

        setup() {
            return;
        },
        components: {
          Editor
        },
        data() {
            return {
                component: "servicio-planning-comunicaciones",
                clientedatos: [],
                titulocliente: '',
                mensajes: '',
            }
        },
        methods: {
            async cargarmensajes() {
                //GET  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/chat-visita/:id_visita
                const api = new PwgsApi();
                this.mensajes = await api.get('planning-pwgs/' + this.$props.id + '/chat-visita/' + this.$props.idvisita);
            },
            async enviarMensaje() {
                //PUT  modulos/pwgsapi/index.php/planning-pwgs/:id_servicio/chat/:id_visita
                const api = new PwgsApi();
                try {
                    let subidadatos = { texto: this.cuerpo_mensaje };
                    await api.put('planning-pwgs/' + this.$props.id + '/chat/' + this.$props.idvisita, subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Mensaje enviado', life: 2000 });
                    this.cargarmensajes();
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: "Error", detail: error, life: 5000 });
                }
            }
        },
        watch: {
            id() {
                this.cargarmensajes();
            }
        },
        mounted() {
            this.cargarmensajes();
        },
    })
</script>
<style>
.visto{
      font-size: small;
}
</style>